<template>
    <div class="enterhistory">
        <div class="title">
            <span class="title-text">
                تاریخچه ورود
            </span>
            <div @click.prevent="$router.push({name:'Settings'})" class="goto">
                <span class="clickable"> تنظیمات امنیتی </span>
                <inline-svg class="arrowsvg" :src="require('../../../assets/icons/inputarrow.svg')" />
            </div>
        </div>


        <div class="history-container">
            <perfect-scrollbar @ps-y-reach-end="check">
            <div v-for="(item,index) in tableContents" :key="index" class="history">
                <div class="row">
                    <div class="column">
                        <span> {{item.browser}} </span>
                        <span> {{item.ipAddress}} </span>
                    </div>
                    <div class="column">
                        <span> {{item.deviceType}} </span>
                        <span> {{item.osType}} </span>
                    </div>
                </div>
                <div class="row">
                    <span> {{item.ipAddress}} </span>
                    <span> {{$G2J(item.loginRequestedDateTime)}} </span>
                </div>
            </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
    export default {
        name:'EnterHistory',
        component:{
            PerfectScrollbar
        },
        data() {
            return {
                loginData:[],
                tableContents:[],
                page:0,
                lastPage:1,
            }
        },
        methods:{
            async check() {
                if (this.page < this.lastPage && !this.loading) { //for scroll loading
                    this.loading = true
                    this.page++ //for scroll loading
                    const [res,] = await this.$http.get('/users/login-history', {
                        params: {
                            size: 10,
                            page: this.page
                        }
                    })
                    if (res) {
                        this.lastPage = res.totalPages
                        this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
                        // this.tableContents = res.content //for pagination
                    }
                    this.loading = false
                }
                this.state.loading = false
            },
        },
        mounted() {
            this.check();
        },
    }
</script>

<style lang="scss" scoped>
.row span{
    font-size: clamp(12px,1vw,14px);
    font-weight: 400;
}
.history-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    color: var(--black);
    gap: 4px;
    .history{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #cacacead;
        padding-bottom: 5px;
    }
    .row{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        justify-content: space-between;
        .column{
            display: flex;
            flex-direction: row;
            column-gap: 4px;
        }
    }
}
.enterhistory{
    height: 100%;
    border-radius: 12px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 12px;
    background: white;
    height: 300px;
}
.title{
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #cacacead;
}
.title-text{
    color: var(--black-fee);
    word-spacing: 4px;
}
.arrowsvg{
    transform: rotate(90deg);
}
.clickable{
    color: var(--dark-primary);
    cursor: pointer;
}
</style>