<template>
    <div class="main-dash">
        <div class="panel-contain">
            <h2> نگاه کلی به بازار </h2>
            <div class="panel-container">
                <!-- <MarketSlider /> -->
                <Slider2 />
                <div class="acandhistory">
                    <AccountCard />
                    <HistoryTable />
                </div>
                <div class="seetingsandfriends">
                    <InviteCards />
                    <EnterHistory />
                </div>
            </div>
        </div>
        <div class="wallet-content2">
            <ShopNav />
            <ApexChart :wallets="wallets" />

            <div class="side-wallets">
                <h3> کیف پول ها </h3>
                <div class="wallets-container">
                    <div v-for="(wallet,index) in balanceWallet" :key="index" class="wallet-container">
                        <div class="wallet-icon">
                            <div class="coin-label">
                                <div :style="'background:url(/coins/'+wallet.relatedCoin+'.png)'" class="coin-icon">
                                    <span> {{$toLocal(wallet.percent,2)}}% </span>
                                </div>
                            </div>
                            <div class="coin-unit">
                                <span class="faname"> {{$coinLabel[wallet.relatedCoin]}} </span>
                                <span class="unitname"> {{$coinUnit[wallet.relatedCoin]}} </span>
                            </div>
                        </div>
                        <div class="wallet-balance">
                            <span> {{$coinUnit[wallet.relatedCoin]}} {{$toLocal(wallet.credit,$decimal[wallet.relatedCoin])}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApexChart from '../Wallet/ApexChart.vue';
import AccountCard from './AccountCard.vue';
// import MarketSlider from './MarketSlider.vue';
import HistoryTable from '../History/HistoryTable.vue';
import InviteCards from './InviteCards.vue';
import EnterHistory from './EnterHistory.vue'
import ShopNav from '../../P2P/ShopNav.vue';
import Slider2 from './Slider2.vue';
    export default {
        name:'Dashboard',
        components:{
            // MarketSlider,
            AccountCard,
            HistoryTable,
            InviteCards,
            EnterHistory,
            ApexChart,
            Slider2,
            ShopNav,
        },
        data() {
            return {
                series: [
                57465764, 45764765
            ],
            balancePrice:0,
            wallets: [],
            balanceWallet:[],
            chartOptions: {
                chart: {
                    width: '50px',
                    fontFamily: 'shabnam',
                },

                // responsive: [{
                //     breakpoint: 400,
                //     options: {
                //         chart: {
                //             width: '150px',
                //         },
                //     },
                // }],

                colors: [
                    '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
                ],
                theme: {
                    mode: 'light',
                    palette: 'palette2'
                },
                stroke: {
                    width: 1,
                    colors: undefined
                },
                labels: [],
                dataLabels: {
                    style: {
                        fontFamily: 'shabnam'
                    }
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                            size: '65%',
                            background: 'white',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'var(--tooDark)',
                                    offsetY: -17,
                                    formatter: function (val) {
                                        return val.name || val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 400,
                                    color: '#3D49CC',
                                    offsetY: 10,
                                    formatter: function (val) {
                                        return parseFloat(val).toLocaleString()
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'ارزش کل دارایی',
                                    fontSize: '12px',
                                    fontFamily: 'shabnam',
                                    fontWeight: 600,
                                    color: 'black',
                                    formatter: function (w) {
                                        w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                        return w.toLocaleString() + '\n' + 'تومان'
                                    }
                                }
                            }
                        },
                    }
                },
                tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                        seriesIndex,
                        w
                    }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                }
            },
            apexToman:true,
            }
        },
        computed:{
            width(){
                return window.innerWidth
            }
        },
        methods: {
            async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
            sortWallets(e){
                const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN'];
                e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.tether = e.filter(a => a[0] == 'TETHER')[0][1]
                this.toman = e.filter(a => a[0] == 'TOMAN')[0][1]
                this.wallets = e.map(a => {
                        let relatedCoin = a[0]
                        if(a.relatedCoin) {
                            a=a[1]
                        }else{
                            a=a[1]
                            a.relatedCoin=relatedCoin
                        }
                        return a
                        })
                    this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                    this.wallets.sort((a,b) => b.credit - a.credit)
                    this.creditPercent();
            },
            creditPercent(){
                this.balanceWallet = this.wallets.filter(a => a.credit > 0)
                this.balanceWallet.map(e => {
                    this.balancePrice += e.wholePriceOfCredit;
                });
                this.balanceWallet.map(e => {
                    e.percent = (e.wholePriceOfCredit / this.balancePrice) * 100
                });


            },
        },
        async mounted() {
            await this.getWallets();
        },
    }
</script>

<style lang="scss" scoped>
.priceSelect{
    img{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);

    }
}
.tetherp{
    right: 0;
}
.tomanp{
    left: 0;
}
.pricetype{
    position: absolute;
    bottom: 10px;
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        width: 80%;
        height: 80%;
    }
}
.side-wallets{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: start;
    width: 100%;
    max-height: 50vh;
    overflow-y: scroll;
    h3{
        align-self: flex-start;
        position: sticky;
        top: 0;
        background: var(--home-background);
        z-index: 24;
        padding: 4px;
        width: 100%;
    }
    .wallets-container{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        width: 100%;
        .wallet-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 12px;
            background: white;
            width: 100%;
            // max-width: 290px;
            border-radius: 12px;
            .wallet-balance{
                display: flex;
                flex-direction: row;
                column-gap: 8px;
                align-items: center;
            }
            .wallet-icon{
                display: flex;
                flex-direction: row;
                column-gap: 12px;
                .coin-label{
                    width: 48px;
                    height: 48px;
                    background: var(--light-yellow);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .coin-icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        // background: url('../../../assets/icons/btc.svg');
                        background-repeat: no-repeat !important;
                        background-blend-mode: screen !important;
                        background-color: rgba(255, 255, 255, 0.8) !important;
                        -webkit-backdrop-filter: blur(5px);
                        backdrop-filter: blur(5px);
                        background-size: 100% !important;
                    }
                }
                .coin-unit{
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    .unitname{
                        color: var(--grey);
                        font-size: clamp(10px,1vw,12px);
                    }
                }
            }
        }
    }
}
.chart-container {
    position: relative;
    display: flex;
    border-radius: 50%;
    background: white;
    width: 268px;
    height: 268px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

}
.apex{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 20px;
}
.wallet-content{
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
    justify-content: start;
    width: 30%;
    padding: 24px;
}
.wallet-content2{
    background: var(--rang-kossher);
    z-index: 132;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
    justify-content: start;
    width: 25%;
    padding: 0 10px;
    height: 100%;
    h3{
        background: var(--rang-kossher) !important;
    }
}
.panel-contain{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 72%;
    overflow: hidden;
}
.panel-container{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    overflow: hidden;
}
.main-dash{
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    height: 100%;
}
.acandhistory{
display: flex;
flex-direction: row;
column-gap: 24px;
justify-content: space-around;
align-items: center;
}
.seetingsandfriends{
display: flex;
flex-direction: row;
column-gap: 24px;
justify-content: space-around;
align-items: center;
}
@media only screen and (max-width:400px) {
    .chart-container{
        width: 200px;
        height: 200px;
    }
}
@media only screen and (max-width:500px) {
    .side-wallets{
        width: 100%;
    }
    .coin-unit{
        display: none !important;
    }
}
@media only screen and(max-width:1200px){
    .panel-container{
        overflow: unset !important;
    }
    .panel-contain{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        width: 100%;
        overflow: unset !important;
    }
    .wallet-content2{
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        align-items: center;
        justify-content: start;
        width: 30%;
        padding: 24px;
        position: unset !important;
        background: unset !important;
        h3{
            background: unset !important;
        }
    }
    .seetingsandfriends{
        flex-direction: column;
        row-gap: 20px;
        .invitecard{
            width: 100%;
        }
        .enterhistory{
            width: 100%;
        }
    }
    .main-dash{
        flex-direction: column;
        row-gap: 30px;
        .panel-contain{
            width: 100%;
        }
        .wallet-content2{
            width: 100%;
        }
    }
    .acandhistory{
        flex-direction: column;
        row-gap: 20px;
        .card-container{
            width: 40%;
        }
        .history{
            width: 90%;
        }
    }
}
@media only screen and(max-width:800px){
    .acandhistory{
        flex-direction: column;
        row-gap: 20px;
        .card-container{
            width: 60%;
        }
        .history{
            width: 90%;
        }
    }
}
@media only screen and(max-width:600px){
    .acandhistory{
        flex-direction: column;
        row-gap: 20px;
        .card-container{
            width: 90%;
        }
        .history{
            width: 100%;
        }
    }
}
</style>