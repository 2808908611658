<template>
    <div class="invitecard">
        <div class="title">
            <span class="title-text">
                سود دعوت از دوستان
            </span>
            <div @click="$router.push('/dashboard/invitefriends')" class="goto">
                <span class="clickable"> ارسال دعوت </span>
                <inline-svg class="arrowsvg" :src="require('../../../assets/icons/inputarrow.svg')" />
            </div>
        </div>
        <div class="body">
            <div class="profit">
                <img src="../../../assets/icons/hoora.svg" alt="">
                <span class="profitnum"> {{$toLocal(data.profit,2)}}تومان  </span>
            </div>
            <span class="invitedfriends">
                {{data.countInvitees}} دوست دعوت شده
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name:'InviteCards',
        data() {
            return {
                profit:2400594,
                data:[],
            }
        },
        methods:{
            async getData(){
                this.state.loading=true;
                const res = await this.$axios.get('/users/invites')
                this.data = res.baseDTO
            }
        },
        mounted() {
            this.getData();
        },
    }
</script>

<style lang="scss" scoped>
.invitecard{
    height: 100%;
    border-radius: 12px;
    width: 35%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 12px;
    background: white;
    min-height: 200px;
}
.profit{
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}
.body{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .invitedfriends{
        color: var(--grey);
    }
}
.title{
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #cacacead;
}
.title-text{
    color: var(--black-fee);
    word-spacing: 4px;
}
.arrowsvg{
    transform: rotate(90deg);
}
.clickable{
    color: var(--dark-primary);
    cursor: pointer;
}
</style>