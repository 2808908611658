<template>
    <div class="card-container">
        <div v-if="state.userInfo.authenticationType !=='AUTHORIZED'" class="warning">
            <h3> {{state.userInfo.email.replace(state.userInfo.email.slice(3,7), '****' )}} </h3>
            <p v-if="state.userInfo.authenticationType !=='AUTHORIZED'" class="warn"> حساب کاربری شما تایید نشده است برای استفاده از امکانات صرافی و معامله باید فرآیند احراز هویت را انجام دهید </p>
            <p v-else>  با انجام تراکنش سطح کاربری خود را ارتقا دهید و از امکانات ویژه ما بهره مند شوید </p>
        </div>
        <div class="user-level">
            <div class="row">
                <div class="user-info">
                    <h3> {{state.userInfo.firstName + ' ' + state.userInfo.lastName}} </h3>
                    <span> سطح کاربری فعلی </span>
                </div>
                <img src="../../../assets/medal.png" alt="">
            </div>
            <div class="row">
                <span class="title"> کارمزد معاملات </span>
                <span class="text"> {{state.userInfo.fee}}% </span>
            </div>
        </div>
        <div class="btns">
            <button v-if="state.userInfo.authenticationType !=='AUTHORIZED'" @click="accountRedirect" class="submit"> {{ state.userInfo.authenticationType =='AUTHORIZED' ? 'حساب کاربری':'احراز هویت کنید'}} </button>
            <button v-if="state.userInfo.authenticationType !=='AUTHORIZED'" class="guidebtn"> راهنمای احراز هویت </button>
            <div v-if="state.userInfo.authenticationType =='AUTHORIZED'" @click.prevent="$router.push({name:'Settings'})" class="goto">
                <span class="clickable"> سطوح کاربری </span>
                <inline-svg class="arrowsvg" :src="require('../../../assets/icons/inputarrow.svg')" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Accountcard',
        methods: {
            accountRedirect(){
                if(this.state.userInfo.authenticationType =='AUTHORIZED'){
                    this.$router.push({name:'Account'})
                }else{
                    this.$router.push({name:'Validation'})
                }
            }
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>
.user-level{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
    .row{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .user-info{
            display: flex;
            flex-direction: column;
            row-gap: 12px;
        }
        .title{
            color: rgba(40, 41, 41, 1);
            font-weight: 400;
            font-size: clamp(11px,1vw,13px);
        }
    }

}
.goto{
    display: flex;
    align-items: center;
    gap: 16px;
    span{
        color: var(--primary-color);
    }
}
.arrowsvg{
    transform: rotate(90deg);
}
.card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    border-radius: 12px;
    background: url('../../../assets/cardBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30%;
    height: 100%;
    min-height: 350px;
}
.warning{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    h3{
        font-size: clamp(18px,2vw,20px);
        font-weight: 700;
    }
}
.btns{
    display: flex;
    flex-direction: row;
    .guidebtn{
        background: none !important;
        color: var(--dark-primary);
    }
    button{
        width: 50%;
    }
}
.warn{
    font-weight: 400;
    font-size: clamp(12px,1vw,14px);
}
@media only screen and(max-width:400px){
    .btns{
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        button{
            width: 100%;
        }
    }
}
</style>