<template>
  <div class="carousel">
<Hooper :settings="hoopersSetting">

<Slide v-for="(item,index) in market" :key="index" class="coin-info">
                    
            <div class="coin-label">
                <img class="coin-img" :src="'/coins/'+$coin2Snake[item.relatedCoin]+'.png'" alt="">
                <TrendChart class="remove2" :data="item.lastWeekData" />
                <span class="coinunit"> {{$coinUnit[item.relatedCoin]}} </span>
            </div>
            <span> {{$coinLabel[item.relatedCoin]}} </span>
            <div class="pricetag">
                <span class="price"> {{$toLocal(item.lastPrice,0)}} </span>
                <span class="pricetype"> تومان </span>
            </div>
            <div class="changes">
                            <span v-if="item.last24HourChange>0" class="success"> {{$toLocal(item.last24HourChange,2)}}% </span>
                            <span v-if="item.last24HourChange<=0" class="fail"> {{ $toLocal(Math.abs(item.last24HourChange),2) }}% </span>
                            <inline-svg :class="item.last24HourChange > 0 ?'success' :'fail'" :src="require('../../../assets/icons/changes.svg')" />
            </div>
            
</Slide>




<hooper-navigation slot="hooper-addons"></hooper-navigation>
</Hooper>
  </div>
</template>

<script>
import TrendChart from '../../Tools/TrendChart.vue'
import {Hooper, Slide,Navigation as HooperNavigation} from 'hooper'
import 'hooper/dist/hooper.css';
export default {
  name: "CoinsCarousel",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    TrendChart
  },
  data() {
    return {
            series:[{
                name: "series-1",
                data: [30, 40, 35, 50, 49, 60, 70, 91],
            }],
            chartOptions:{
                chart: {
                    id: "vuechart-example",
                    },
                    xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                    },
            },







        

        market:[],



      hoopersSetting:{

        rtl:true,
        infiniteScroll:true,
        autoPlay:true,
        autoplaySpeed: 2000,
        itemsToShow: 6,
        centerMode:true,
        breakpoints : {
          1200:{
            itemsToShow: 4,
            centerMode:true,
          },
          800:{
            itemsToShow: 3,
            centerMode:true,
          },
          500:{
            itemsToShow:2,

          },
          320:{
            itemsToShow:1,
            centerMode:true,
          }
        }
      },

      settings: {
        rtl: true,
        arrows: true,
        autoplay: false,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplaySpeed: 600,
        useCSS: true,
      }
    }
  },
  methods:{
            async lastTrades(){
                const res  = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.market=res.content 
                    this.market=this.market.filter(a => a.marketType.includes('TOMAN'))
            },
    },
    mounted(){
        this.lastTrades();
    }
}
</script>

<style lang="scss" scoped>
.slider-contaienr{
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    border-radius: 12px;
    padding: 12px;
    background: var(--slider-back);
}
.coin-info{
    max-width: 180px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px;
    background: white;
    justify-content: flex-start;
    align-items: flex-start;
    .coin-label{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 10px;
        .coin-img{
            width: 30px;
            height: 30px;
        }
        .coinunit{
            align-self: flex-end;
        }
    }
    .pricetag{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        .pricetype{
        color: var(--grey);
        }
    }
    .changes{
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
    }
}
</style>